import { navigationRoutes } from '../components/admin/app-sidebar/NavigationRoutes'
import Vue from 'vue'
import vueResource from 'vue-resource'
Vue.use(vueResource)

export var sidebar = function () {
  var flag_arr = ['ad_provision', 'create_org_flag']
  var org_create_provision = Vue.$cookies.get('org_create_provision')
  var role = Vue.$cookies.get('roles')
  var operator_setting_flag = false
  var create_org_flag = 'false'
  // var ad_provision =  true;

  // const credit_system_map={
  //   'PCS':"Platform Credit System",
  //   'MCS':"MSO Credit System"
  // }

  // ad_provision=Vue.$cookies.get('ad_provision')
  // if(Vue.$cookies.get('credit_system')){
  //   var creditSystem = JSON.parse(Vue.$cookies.get('credit_system'))
  // }

  // if((role.indexOf("SYS_ADMIN")>-1)){
  //   operator_setting_flag=true;
  //   create_org_flag="true";
  // }

  // if((role.indexOf("OPERATOR_ADMIN")>-1)){
  //   ad_provision=Vue.$cookies.get('ad_provision')
  //   create_org_flag="true";
  //   if(creditSystem && creditSystem.some(function(item){return item.credit_system==credit_system_map.PCS })){
  //     operator_setting_flag=true;
  //   }
  // }
  // if((org_create_provision === "true") && (role.indexOf("MSO_ADMIN")>-1) && (creditSystem && creditSystem.some(function(item){return item.credit_system==credit_system_map.MCS }))){
  //   create_org_flag="true"
  // }
  var navigation_routes = []
  navigation_routes = navigationRoutes.routes_copy
  var user_role = role
  var routes_data_by_role = []
  var map = {
    ad_provision: Vue.$cookies.get('ad_provision'),
    allowed_app: Vue.$cookies.get('allowed_app'),
    local_channel: Vue.$cookies.get('local_channel'),
    create_org_flag: create_org_flag,
    operator_setting_flag: operator_setting_flag,
    enable_reseller_bundle_creation: Vue.$cookies.get('enable_reseller_bundle_creation')
  }
  for (var i = 0; i < navigation_routes.length; i++) {
    var prop = navigation_routes[i]
    var item = prop.meta
    if (item.authorize.includes(user_role)) {
      if (prop.children) {

        var children = prop.children
        var childArr = []
        children.map(function (child) {
          if (child.meta.authorize.includes(user_role)) {
            if (user_role == 'RESELLER' || user_role == 'HEPI_RESELLER'){
              if(child.name == 'reseller_custom'){
                if(map.enable_reseller_bundle_creation == 1){
                  childArr.push(child)
                  return false
                }else{
                  return false
                }
              }
            }
            if (user_role == 'ADMIN' || user_role == 'SUPPORT') {

              childArr.push(child)
            } else {
              if (child.name == 'operator_adslot' || child.name == 'advertisement_slot' || child.name == 'advertisement') {

                if (map.ad_provision == 'true') {

                  childArr.push(child)
                  return false
                } 
                else {
                  return false
                }
              }
              if(child.name == 'allowed_app'){

                if ((map.allowed_app == 'true') || Number(map.allowed_app)) {

                  childArr.push(child)
                  return false
                } 
              }
              /* else if(child.name == 'notification' || child.name == 'app_setting'){
                if((Vue.$cookies.get('roles') == 'OPERATOR') && (Vue.$cookies.get('oper_head') != 'RESELLER')){
                  childArr.push(child)
                }
                if(Vue.$cookies.get('roles') == 'RESELLER'){
                  childArr.push(child)
                }
              } */
              else {
                childArr.push(child)
              }
            }
          }
          prop.children = childArr
        })
        if (user_role == 'OPERATOR') {
          if (prop.name == 'advertisement') {
            if (map.ad_provision == true) {
              routes_data_by_role.push(prop)
            }
          } else {
            routes_data_by_role.push(prop)
          }
        } else {
          routes_data_by_role.push(prop)
        }
      } else {
        if(user_role == 'OPERATOR' || user_role == 'HEPI_OPERATOR'){
          if (prop.name == 'cloud_tv'){
            if(map.local_channel == 1){
              routes_data_by_role.push(prop)
            }
          }else{
            routes_data_by_role.push(prop)
          }
        }else{
          routes_data_by_role.push(prop)
        }
        
      }
    }
  }
  return navigationRoutes.routes = routes_data_by_role
}
