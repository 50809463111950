<template>

  <div>

    <div class="auth-layout row align-content--center" style="display: none;">
      <div class="flex xs12 pa-3 flex-center">
        <router-link class="py-5 flex-center" to="/">
        </router-link>
      </div>

      <div class="flex xs12 pa-3">
        <div class="d-flex justify--center">
          <va-card class="auth-layout__card">
            <va-tabs
              v-model="tabIndex"
              center>
              <va-tab>Login</va-tab>
            </va-tabs>
            <va-separator/>
            <div class="pa-3">
              <router-view/>
            </div>
          </va-card>
        </div>
      </div>
    </div>



    <div class="row auth-layout">
      <div class="flex xs12 pa-3 flex-center auth-display-none">
        <router-link class="py-5 flex-center" to="/">
        </router-link>
      </div>      
      <div class="col justify--center logo-container">
        <img class="icon" src="@/assets/Group-3.png" />
        <img :src=image_url />
      </div>
      <div class="col justify--center login-container">
        <div class="pa-3 auth-layout__card">
          <router-view/>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>

const tabs = [
  'login',
  'signup',
  'network'
]

import config from '../../i18n/en'
import Vue from 'vue'
import image_config from '../../i18n/image'
import vueResource from 'vue-resource'
Vue.use(vueResource)

export default {
  name: 'AuthLayout',
  created(){
     var domain = location.host
     this.image_url = image_config[domain]['url']
  },
  components: { },
  data () {
    return {
      selectedTabIndex: 0,
      keyEnable : true,
      tabTitles: ['login'],
      image_url: 'https://app.skie.tv/portal_logo/skie.png',
      tabTitles: ['login', 'createNewAccount'],
    }
  },
  computed: {
    tabIndex: {
      set (tabIndex) {
        this.$router.push({ name: tabs[tabIndex] }).catch(()=>{});
      },
      get () {
        return tabs.indexOf(this.$route.name)
      },
    },
  },
}
</script>

<style lang="scss">
.auth-layout {
  align-items: center;
  height: 100vh;

  &__card {
    width: 100%;
    max-width: 600px;
  }

  &__options {
    @include media-breakpoint-down(xs) {
      flex-direction: column;
    }
  }
}

.auth-display-none {
  display: none;
}

.flex-auto {
  flex: auto;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
}

.logo-container {
  height: 100%;
  align-items: center;
  background: #121212;
  position: relative;

  & .icon {
    width: 200px;
    height: 200px;
    position: absolute;
    top: -55px;
    left: -65px;
  }

  & .font-label {
    font-size: 40px;
    color: white;
  }
}

.login-container {
  height: 100%;
  align-items: center;
  background: $alpha-color;
}
</style>